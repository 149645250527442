html {
  height: 100vh;
}

body {
  background-color: #e7e5df !important;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
  max-width: 800px !important;
  margin: 0 auto !important;
  padding: 30px !important;
  font-weight: 300 !important;
  display: flex;
  flex-direction: column;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  background: transparent;
}
